import React, { Component } from 'react'
import './loadingPage.scss'
import Loader from 'react-loaders'
import 'loaders.css/src/animations/ball-clip-rotate.scss'

class LoadingPage extends Component {
  render () {
    return <Loader type='ball-clip-rotate' className='loading-page' />
  }
}
export default LoadingPage
