import React, { Component } from "react";
import { connect } from "react-redux";
import * as Storage from "../services/localstorage";
import "./header.scss";
import logoImage from "../assets/images/logo.jpeg";
import {
  Row,
  Col,
  Navbar,
  Nav,
  NavItem,
  Collapse,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { updateLoginFlag } from "../../store/actions/auth";
import { HiOutlinePhone } from "react-icons/hi";
import { FaRegEnvelope } from "react-icons/fa";
import { VscSignIn, VscSignOut } from "react-icons/vsc";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import customerService from "../services/customerService";
import axios from "axios";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      logo: {},
      fetchingData: false,
    };
  }
  componentDidMount() {
    this.getcustomerLogo("refresh");
  }
  //Get Logo us API
  getcustomerLogo = (reloadStatus) => {
    if (reloadStatus === "refresh") {
      this.setState({ fetchingData: true });
    }
    var thisView = this;
    axios
      .all([customerService.getcustomerLogo()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let logo = resData.data ? resData.data : [];
            logo = logo.data ? logo.data : [];
            thisView.setState({ logo: logo[0] });
          } else {
            thisView.setState({ apiError: resData.msg });
          }
        }
        thisView.setState({ fetchingData: false });
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Fetching Logo ");
      });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  logout = (event) => {
    event.preventDefault();
    Storage.remove(this.props.config.sessionKey);
    this.props.history.push({ pathname: "/home" });
    window.location.reload();
  };
  render() {
    const { mobileNumber, config, contactDetails } = this.props;
    const { logo } = this.state;
    return (
      <div className="header-new-container">
        <div className="top-header">
          <div className="container">
            <Row>
              <Col md={6}>
                <div className="top-header-left">
                  {contactDetails && contactDetails.header_phone && (
                    <p>
                      <HiOutlinePhone className=" top-header-icon" />{" "}
                      {contactDetails.header_phone}
                    </p>
                  )}
                  {contactDetails && contactDetails.header_email && (
                    <p>
                      <FaRegEnvelope className=" top-header-icon" />{" "}
                      {contactDetails.header_email}
                    </p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="top-header-right">
                  {mobileNumber && mobileNumber ? (
                    <>
                      <Link to={"/profile/dashboard"}>
                        <CgProfile className="top-header-icon" />
                        Profile
                      </Link>
                      <p onClick={(e) => this.logout(e)} className="link-tag">
                        <VscSignOut className="top-header-icon" />
                        Sign Out
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="link-tag"
                        onClick={() => {
                          this.props.updatingLoginFlag(true);
                        }}
                      >
                        <VscSignIn className="top-header-icon" />
                        Sign In
                      </p>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Navbar light className=" navbar-expand-lg   ">
          <div className="container">
            <NavbarBrand>
              <img
                src={logo.logo ? config.fileBasicPath + logo.logo : logoImage}
                className="logo-img"
                alt="logo"
              />
              {/* <span className='logo-text'>Emicarz</span> */}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto navbar-nav" navbar>
                <NavItem>
                  <NavLink to={"/home"} className="nav-link">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/about"} className="nav-link">
                    About Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/cars"} className="nav-link">
                    Cars
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/testimonial"} className="nav-link">
                    Testimonial
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/faq"} className="nav-link">
                    Faq
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/pricing"} className="nav-link">
                    Pricing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={'/termsCondition'} className='nav-link'>
                    Terms & Conditions
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink to={'/privacyPolicy'} className='nav-link'>
                    Privacy Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={'/refund'} className='nav-link'>
                    Refund Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/contact"} className="nav-link">
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  config: state.auth.config,
  emailId: state.auth.emailId,
  mobileNumber: state.auth.mobileNumber,
  contactDetails: state.auth.contactDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updatingLoginFlag(flag) {
    dispatch(updateLoginFlag(flag));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
