export const AUTHENTICATE = 'AUTHENTICATE';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_LOGIN_FLAG = 'EXPORT_LOGIN_FLAG';
export const UPDATE_SIGNUP_FLAG = 'UPDATE_SIGNUP_FLAG';
export const CONTACT_DETAILS = ' CONTACT_DETAILS';

export const authenticate = (data) => {
  return { type: AUTHENTICATE, data: data };
};

export const updateConfig = (config) => {
  return { type: UPDATE_CONFIG, data: config };
};
export const contactDetails = (data) => {
  return { type: CONTACT_DETAILS, data: data };
};
export const updateLoginFlag = (flag) => {
  return { type: UPDATE_LOGIN_FLAG, data: flag };
};

export const updateSingupFlag = (flag) => {
  return { type: UPDATE_SIGNUP_FLAG, data: flag };
};
