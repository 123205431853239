import React from "react";
import { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Base from "../../layout/AdminBase";
import Basepages from "../../layout/Basepages";
import "./adminRoutes.scss";
import { authenticate } from "../../../store/actions/auth";
import * as Storage from "../../services/localstorage";
import Notifications from "react-notify-toast";
import { useToasts } from "react-toast-notifications";
import LoadingPage from "../../assets/elements/loadingPage/loadingPage";
const Login = lazy(() => import("../../containers/admin/login/login"));
const Home = lazy(() => import("../../containers/admin/home/home"));
const CarsList = lazy(() => import("../../containers/admin/carsList/carsList"));
const ListOnTop = lazy(() => import("../../containers/admin/listOnTop/listontop"));

const BiddingCarsList = lazy(() =>
  import("../../containers/admin/biddingList/biddingList")
);

const PendingCarsList = lazy(() =>
  import("../../containers/admin/pendingCarsList/pendingCarsList")
);
const Brands = lazy(() => import("../../containers/admin/brands/brands"));
const Models = lazy(() => import("../../containers/admin/modals/modals"));
const Variants = lazy(() => import("../../containers/admin/variants/variants"));
const About = lazy(() => import("../../containers/admin/about/about"));
const Faqs = lazy(() => import("../../containers/admin/faqs/faqs"));
const Contacts = lazy(() => import("../../containers/admin/contacts/contacts"));
const Customers = lazy(() =>
  import("../../containers/admin/customers/customers")
);
const Dealers = lazy(() => import("../../containers/admin/dealers/dealers"));
const Fuel = lazy(() => import("../../containers/admin/fuel/fuel"));
const CarCategory = lazy(() =>
  import("../../containers/admin/carCategory/carCategory")
);
const BodyType = lazy(() => import("../../containers/admin/bodyType/bodyType"));
const Testimonial = lazy(() =>
  import("../../containers/admin/testimonial/testimonial")
);
const Logo = lazy(() => import("../../containers/admin/logo/logo"));
const Banner = lazy(() => import("../../containers/admin/banner/banner"));
const ErrorPage = lazy(() => import("../../layout/errorPage"));
const Pricing = lazy(() => import("../../containers/admin/pricing/pricing"));
const Payments = lazy(() => import("../../containers/admin/payments/payments"));
const Messages = lazy(() => import("../../containers/admin/messages/messages"));
const Newsletter = lazy(() =>
  import("../../containers/admin/newsletter/newsletter")
);
const Settings = lazy(() => import("../../containers/admin/settings/settings"));

const FinanceCars = lazy(() =>
  import("../../containers/admin/financeCars/FinanceCars")
);

const Cars4UCars = lazy(() =>
  import("../../containers/admin/carz4UCars/Carz4UCars")
);
const ProductCategory = lazy(() =>
  import("../../containers/admin/spareParts/productCategory/ProductCategory")
);
const Products = lazy(() =>
  import("../../containers/admin/spareParts/products/Products")
);
const Bookings = lazy(() =>
  import("../../containers/admin/bookings/Bookings")
);
const Insurance = lazy(() =>
  import("../../containers/admin/insurance/Insurance")
);
const AppUsage = lazy(() =>
  import("../../containers/admin/aapUsage/AppUsage")
);

const listofPages = ["/", "/login", "/error"];
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
class AdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // console.log('tools main')
    this.checkUserSession();
  }

  checkUserSession = () => {
    let session = Storage.get(this.props.config.sessionKey);
    let authData = session ? JSON.parse(session) : {};
    let response = Storage.checkUserSession(authData);
    if (response.status) {
      this.props.updatingAuthenticate(response.data);
    } else {
      this.props.history.push({ pathname: "/login" });
    }
  };

  componentWillUnmount() {}

  render() {
    const { location } = this.props;
    // console.log(location, listofPages.indexOf(location.pathname))
    return listofPages.indexOf(location.pathname) > -1 ? (
      <Basepages {...this.props}>
        <Suspense fallback={<LoadingPage />}>
          <Notifications options={{ zIndex: 1, top: "0" }} />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => {
                return <Login {...props} {...this.props} />;
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return <Login {...props} {...this.props} />;
              }}
            />
            <Route
              path="/error"
              exact={true}
              render={(props) => {
                return <ErrorPage {...props} {...this.props} />;
              }}
            />
            <Redirect to="/error" />
          </Switch>
        </Suspense>
      </Basepages>
    ) : (
      <Base location={location} {...this.props}>
        <Suspense fallback={<LoadingPage className="admin-route" />}>
          <Notifications options={{ zIndex: 1, top: "0" }} />
          <Switch>
            <Route
              path="/home"
              render={(props) => {
                return <Home {...props} {...this.props} />;
              }}
            />
            <Route
              path="/carsList/:carId"
              render={(props) => {
                return <Messages {...this.props} {...props} />;
              }}
            />
            <Route
              path="/carsList"
              render={(props) => {
                return <CarsList {...props} {...this.props} />;
              }}
            />
            <Route
              path="/listontop"
              render={(props) => {
                return <ListOnTop {...props} {...this.props} />;
              }}
            />
            <Route
              path="/biddingcars"
              render={(props) => {
                return <BiddingCarsList {...props} {...this.props} />;
              }}
            />
            <Route
              path="/pendingcars"
              render={(props) => {
                return <PendingCarsList {...props} {...this.props} />;
              }}
            />
            <Route
              path="/financecars"
              render={(props) => {
                return <FinanceCars {...props} {...this.props} />;
              }}
            />
            <Route
              path="/carz4ucars"
              render={(props) => {
                return <Cars4UCars {...props} {...this.props} />;
              }}
            />
            <Route
              path="/dealers"
              render={(props) => {
                return <Dealers {...props} {...this.props} />;
              }}
            />
            <Route
              path="/customers"
              render={(props) => {
                return <Customers {...props} {...this.props} />;
              }}
            />
            <Route
              path="/contacts"
              render={(props) => {
                return <Contacts {...props} {...this.props} />;
              }}
            />
            <Route
              path="/newsletter"
              render={(props) => {
                return <Newsletter {...props} {...this.props} />;
              }}
            />
            <Route
              path="/about"
              render={(props) => {
                return <About {...props} {...this.props} />;
              }}
            />
            <Route
              path="/faqs"
              render={(props) => {
                return <Faqs {...props} {...this.props} />;
              }}
            />
            <Route
              path="/pricing"
              render={(props) => {
                return <Pricing {...props} {...this.props} />;
              }}
            />
            <Route
              path="/brands"
              render={(props) => {
                return <Brands {...props} {...this.props} />;
              }}
            />
            <Route
              path="/models"
              render={(props) => {
                return <Models {...props} {...this.props} />;
              }}
            />
            <Route
              path="/variants"
              render={(props) => {
                return <Variants {...props} {...this.props} />;
              }}
            />
            <Route
              path="/fuel"
              render={(props) => {
                return <Fuel {...props} {...this.props} />;
              }}
            />
            <Route
              path="/bodytype"
              render={(props) => {
                return <BodyType {...props} {...this.props} />;
              }}
            />
            <Route
              path="/carcategory"
              render={(props) => {
                return <CarCategory {...props} {...this.props} />;
              }}
            />
            <Route
              path="/testimonial"
              render={(props) => {
                return <Testimonial {...props} {...this.props} />;
              }}
            />
            <Route
              path="/logo"
              render={(props) => {
                return <Logo {...props} {...this.props} />;
              }}
            />
            <Route
              path="/banner"
              render={(props) => {
                return <Banner {...props} {...this.props} />;
              }}
            />
            <Route
              path="/payments"
              render={(props) => {
                return <Payments {...props} {...this.props} />;
              }}
            />
            <Route
              path="/settings"
              render={(props) => {
                return <Settings {...props} {...this.props} />;
              }}
            />
            <Route
              path="/product-category"
              render={(props) => {
                return <ProductCategory {...props} {...this.props} />;
              }}
            />
            <Route
              path="/products"
              render={(props) => {
                return <Products {...props} {...this.props} />;
              }}
            />
            <Route
              path="/bookings"
              render={(props) => {
                return <Bookings {...props} {...this.props} />;
              }}
            />
            <Route
              path="/insurance"
              render={(props) => {
                return <Insurance {...props} {...this.props} />;
              }}
            />
            <Route
              path="/app-usage"
              render={(props) => {
                return <AppUsage {...props} {...this.props} />;
              }}
            />
            <Redirect to="/error" />
          </Switch>
        </Suspense>
      </Base>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.auth.config,
  emailId: state.auth.emailId,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  updatingAuthenticate(data) {
    dispatch(authenticate(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(AdminRoutes));
