import React from "react";
import "./Footer.scss";
import { connect } from "react-redux";
import FirstIcon from "../assets/images/footer-icon/first-icon.png";
import SecondIcon from "../assets/images/footer-icon/second-icon.png";
import ThirdIcon from "../assets/images/footer-icon/third-icon.png";
import FourthIcon from "../assets/images/footer-icon/fourth-icon.png";
import PlayStore from "../assets/images/play-store.png";
import Appstore from "../assets/images/app-store.png";
// import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';
import { Modal, ModalBody, ModalHeader, Input, Button } from "reactstrap";
import { VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { updateLoginFlag } from "../../store/actions/auth";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  PinterestShareButton,
  PinterestIcon,
  HatenaShareButton,
  HatenaIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LineShareButton,
  LineIcon,
  LivejournalShareButton,
  LivejournalIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";
import { Formik } from "formik";
import validate from "../assets/formik/validations";
import customerService from "../services/customerService";
import axios from "axios";
import LoadingIcon from "../assets/elements/loadingSmall/loadingIcon";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareIcon: false,
      shareIconToggle: false,
      email: "",
      socialMediList: [],
    };
  }
  componentDidMount() {
    this.getSocialMedia("reload");
  }

  //Fetch  API
  getSocialMedia = (reload) => {
    if (reload === "reload") {
      this.setState({ fecthingSocialMediaDetails: true });
    }
    var thisView = this;
    axios
      .all([customerService.getSocialMedia()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let socialMediList = resData.data ? resData.data : [];
            socialMediList = socialMediList.data ? socialMediList.data : [];
            socialMediList = socialMediList.map((eachData, index) => {
              return {
                sNo: index + 1,
                ...eachData,
              };
            });
            thisView.setState({ socialMediList });
          } else {
            thisView.setState({ apiError: resData.msg });
          }
        }
        thisView.setState({ fecthingSocialMediaDetails: false });
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Fetching ");
      });
  };
  //... Insert  API ...//
  addNewsletter = (values) => {
    this.setState({ insertingDetails: true });
    const details = {
      email: values.email,
    };
    var thisView = this;
    axios
      .all([customerService.addNewsletter(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            thisView.setState({
              email: "",
            });
            thisView.props.addToast("Thanks For Subscribing", {
              appearance: "success",
              autoDismiss: "true",
            });
          } else {
            thisView.setState({ apiError: res[0].msg });
          }
        }
        thisView.setState({ insertingDetails: false });
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in Inserting Brand ");
      });
  };
  shareIcontoggle = () => {
    this.setState({ shareIcon: !this.state.shareIcon });
  };
  shareIconModel = () => {
    this.setState({ shareIconToggle: !this.state.shareIconToggle });
  };
  render() {
    const {
      shareIcon,
      shareIconToggle,
      insertingDetails,
      email,
      socialMediList,
    } = this.state;
    const { mobileNumber, contactDetails } = this.props;
    return (
      <div className="footer-section">
        <div className="footer-start">
          <footer className="footer ">
            <div className="container footer-grid-icon">
              <div className="row">
                <div className="col-sm-3">
                  <div className="icon-img">
                    <img src={FirstIcon} alt="icon" />
                  </div>
                  <div className="title-text-section">
                    <div className="title">India’s #1</div>
                    <p>Largest Auto portal</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="icon-img">
                    <img src={SecondIcon} alt="icon" />
                  </div>{" "}
                  <div className="title-text-section">
                    <div className="title">Car Sold</div>
                    <p>Every 4 minute</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="icon-img">
                    <img src={ThirdIcon} alt="icon" />
                  </div>{" "}
                  <div className="title-text-section">
                    <div className="title">Offers</div>
                    <p>Stay updated pay less</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="icon-img">
                    <img src={FourthIcon} alt="icon" />
                  </div>{" "}
                  <div className="title-text-section">
                    <div className="title">Compare</div>
                    <p>Decode the right car</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="newsletter-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="newsletter-headline">
                      <h2>Newsletter</h2>
                    </div>
                    <div className="newsletter-submit">
                      <Formik
                        initialValues={{
                          email,
                        }}
                        validationSchema={validate.newsletterSchema}
                        onSubmit={(values, { resetForm }) => {
                          this.addNewsletter(values);
                          resetForm({ values: "" });
                        }}
                        render={({
                          errors,
                          touched,
                          handleChange,
                          values,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <>
                            <Input
                              type="text"
                              placeholder="Enter Your Email"
                              className="newsletter-input"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email ? values.email : email}
                            />
                            {insertingDetails ? (
                              <Button
                                type="submit"
                                className="newsletter-btn"
                                disabled
                              >
                                <LoadingIcon />
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                onClick={handleSubmit}
                                className="newsletter-btn"
                              >
                                Submit
                                {/* <LoadingIcon /> */}
                              </Button>
                            )}
                            {validate.displayError(touched, errors, "email")}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <nav className="footer-nav">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="title">OVERVIEW</div>
                    <ul>
                      <li>
                        <Link to={"/about"}>About us</Link>
                      </li>
                      <li>
                        <Link to={"/testimonial"}>Testimonial</Link>
                      </li>
                      <li>
                        <Link to={"/faq"}>FAQ</Link>
                      </li>
                      <li>
                        <Link to={"/sitemap"}>Sitemap</Link>
                      </li>
                      <li>
                        <Link to={"/termsCondition"}>Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to={"/privacyPolicy"}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={"/refund"}>Refund Policy</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <div className="title">OTHERS</div>
                    <ul>
                      <li>
                        <Link to={"/cars"}> Cars</Link>
                      </li>
                      <li>
                        {mobileNumber ? (
                          <Link to={"/profile/cars"}>Sell Car</Link>
                        ) : (
                          <button
                            type="button"
                            className="link-button"
                            onClick={() => {
                              this.props.updatingLoginFlag(true);
                            }}
                          >
                            Sell Car
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <div className="title">CONNECT WITH US</div>
                    <ul>
                      {contactDetails && contactDetails.header_phone && (
                        <li className="hoverremove ">
                          <span>{contactDetails.header_phone} </span>
                        </li>
                      )}
                      {contactDetails && contactDetails.header_email && (
                        <li>
                          <span>{contactDetails.header_email}</span>
                        </li>
                      )}
                      <li>
                        <Link to={"/contact"}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <div className="title">EXPERIENCE EMICARS APP</div>
                    <ul className="row appholder">
                      <li className="col-sm-6 pl-0 ml-0">
                        <a
                          href="https://itunes.apple.com/"
                          title="Download  Free iOS App"
                        >
                          <img
                            alt="Download  Free iOS App"
                            src={Appstore}
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li className="col-sm-6 app-button pl-0 ml-0">
                        <a
                          href="https://play.google.com/store"
                          title="Download  Free Android App"
                        >
                          <img
                            alt="Download  Free Android App"
                            src={PlayStore}
                            className="img-fluid"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="SocialIcons mt-4">
                      <div className="title">Follow us</div>
                      <ul>
                        {socialMediList &&
                          socialMediList.length > 0 &&
                          socialMediList.map((eachTag, index) => {
                            return (
                              <li>
                                <a
                                  href={eachTag.social_media_url}
                                  title={eachTag.social_media_name}
                                >
                                  <i
                                    className={`${eachTag.font_icon} social-icon`}
                                  />
                                </a>
                              </li>
                            );
                          })}
                        {/* <li>
                          <a href='https://twitter.com' title='Twitter'>
                            <FaTwitter className='social-icon' />
                          </a>
                        </li>
                        <li>
                          <a href='https://www.youtube.com' title='Youtube'>
                            <FaYoutube className='social-icon' />
                          </a>
                        </li>
                        <li>
                          <a href='https://www.instagram.com' title='Instagram'>
                            <FaInstagram className='social-icon' />
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="footerbottom">
              <div className="container">
                <div className="row">
                  <div className="col-xs-4">
                    <p className="copyRight">{contactDetails.copy_right}</p>
                  </div>
                  <div className="col-xs-8" />
                </div>
              </div>
            </div>
          </footer>
        </div>

        <div id="st-2" className="st-left  st-has-labels">
          <span className={`${shareIcon ? "st-left-heddin" : "st-left-open"}`}>
            <div className="st-btn st-first" data-network="facebook">
              <FacebookShareButton url={`https://emicarz.com/demo/customer/`}>
                <img
                  alt="facebook sharing button"
                  src="https://platform-cdn.sharethis.com/img/facebook.svg"
                />
                <span className="st-label">Share</span>
              </FacebookShareButton>
            </div>
            <div className="st-btn" data-network="twitter">
              <TwitterShareButton url={`https://emicarz.com/demo/customer/`}>
                <img
                  alt="twitter sharing button"
                  src="https://platform-cdn.sharethis.com/img/twitter.svg"
                />
                <span className="st-label">Tweet</span>
              </TwitterShareButton>
            </div>
            <div className="st-btn" data-network="pinterest">
              <PinterestShareButton
                media="pinterest"
                url={`https://emicarz.com/demo/customer/`}
              >
                <img
                  alt="pinterest sharing button"
                  src="https://platform-cdn.sharethis.com/img/pinterest.svg"
                />
                <span className="st-label">Pin</span>
              </PinterestShareButton>
            </div>
            <div className="st-btn" data-network="email">
              <EmailShareButton url={`https://emicarz.com/demo/customer/`}>
                <img
                  alt="email sharing button"
                  src="https://platform-cdn.sharethis.com/img/email.svg"
                />
                <span className="st-label">Email</span>
              </EmailShareButton>
            </div>
            <div
              className="st-btn st-last"
              data-network="sharethis"
              onClick={() => this.shareIconModel()}
            >
              <img
                alt="sharethis sharing button"
                src="https://platform-cdn.sharethis.com/img/sharethis.svg"
              />
              <span className="st-label">Share</span>
            </div>
          </span>
          <div className="st-toggle" onClick={() => this.shareIcontoggle()}>
            <div className="st-left">
              <span>
                {shareIcon ? (
                  <img
                    alt="arrow_left sharing button"
                    src="https://platform-cdn.sharethis.com/img/arrow_left.svg"
                  />
                ) : (
                  <img
                    alt="arrow_right sharing button"
                    src="https://platform-cdn.sharethis.com/img/arrow_right.svg"
                  />
                )}
              </span>
            </div>
          </div>
        </div>

        <Modal isOpen={shareIconToggle} className="share-icon-modal">
          <ModalHeader>
            <div className="header-section">
              <h3>Share</h3>
              <VscClose
                className="close-icon"
                onClick={() => this.shareIconModel()}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="dropdown-content">
              <FacebookShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>

              <WhatsappShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>

              <LinkedinShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <LinkedinIcon size={40} round={true} />
              </LinkedinShareButton>

              <TwitterShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>

              <TelegramShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <TelegramIcon size={40} round={true} />
              </TelegramShareButton>

              <EmailShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <EmailIcon size={40} round={true} />
              </EmailShareButton>

              <PinterestShareButton
                className="social-media-btn"
                media="pinterest"
                url={`https://emicarz.com/demo/customer}`}
              >
                <PinterestIcon size={40} round={true} />
              </PinterestShareButton>

              <WorkplaceShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <WorkplaceIcon size={40} round={true} />
              </WorkplaceShareButton>

              <VKShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <VKIcon size={40} round={true} />
              </VKShareButton>

              <ViberShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <ViberIcon size={40} round={true} />
              </ViberShareButton>

              <TumblrShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <TumblrIcon size={40} round={true} />
              </TumblrShareButton>

              <RedditShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <RedditIcon size={40} round={true} />
              </RedditShareButton>
              <PocketShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <PocketIcon size={40} round={true} />
              </PocketShareButton>

              <OKShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <OKIcon size={40} round={true} />
              </OKShareButton>

              <MailruShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <MailruIcon size={40} round={true} />
              </MailruShareButton>

              <LivejournalShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <LivejournalIcon size={40} round={true} />
              </LivejournalShareButton>

              <LineShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <LineIcon size={40} round={true} />
              </LineShareButton>

              <InstapaperShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <InstapaperIcon size={40} round={true} />
              </InstapaperShareButton>

              <HatenaShareButton
                className="social-media-btn"
                url={`https://emicarz.com/demo/customer}`}
              >
                <HatenaIcon size={40} round={true} />
              </HatenaShareButton>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.auth.config,
  mobileNumber: state.auth.mobileNumber,
  contactDetails: state.auth.contactDetails,
});
const mapDispatchToProps = (dispatch) => ({
  updatingLoginFlag(flag) {
    dispatch(updateLoginFlag(flag));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
