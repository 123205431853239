const apiService = {
  //.. APIS ..//

  adminLogin: "user/login",

  //..File Upload ../
  // file_fileUpload: 'file/file_upload',
  file_fileUpload: "user/do_file_upload",

  file_fileUploadDealer: "dealer/do_file_upload",
  customerFileUpload: "customer/do_file_upload",

  //..........................Admin   APIS Start ......................//

  //..Admin Add Cars  APIS ...//
  addUsedCars: "user/add_usedCars",
  getusedCars: "user/used_cars",
  usedCarsDetails: "user/used_cars_details",
  deleteUsedCars: "user/delete_used_cars",
  updateUsedCars: "user/update_used_cars",
  updateCarStatus: "user/update_car_status",

  // Posting apis
  getPostings: "user/postings",
  updatePosting: "user/update_postings",
  deletePosting: "user/delete_postings",
  updateListOnTop:"/user/update_list_on_top",
  updateVerification:"/user/update_verification",
  
  //.. Admin Brand APIS ..//
  addUsedCarBrand: "user/add_used_car_brand",
  updateUsedCarBrand: "user/update_used_car_brand",
  getBrands: "user/used_car_brand",
  deleteUsedCarBrand: "user/delete_used_car_brand",

  //.. Admin Brand by Model APIS ..//
  fetchModelByBrand: "user/used_car_model",
  adduUsedCarModel: "user/add_used_car_model",
  updateUsedCarModel: "user/update_used_car_model",
  deleteUsedCarModel: "user/delete_used_car_model",

  //.. Admin Model by Variant APIS ..//
  fetchAdminModelByVariant: "user/used_car_varriant",
  addUsedCarVariant: "user/add_used_car_varriant",
  updateUsedCarVariant: "user/update_used_car_varriant",
  deleteUsedCarVariant: "user/delete_used_car_varriant",

  //.. Admin Fuel APIS ..//
  addAdminFuel: "user/add_used_car_fuel",
  updateAdminFuel: "user/update_used_car_fuel",
  getAdminFuel: "user/used_car_fuel",
  deleteAdminFuel: "user/delete_used_car_fuel",

  //.. Admin Car Category APIS ..//
  addAdminCarCategory: "user/add_category",
  updateAdminCarCategory: "user/update_category",
  getAdminCarCategory: "user/category",
  getFilterCarCategory: "customer/category",
  deleteAdminCarCategory: "user/delete_category",
  categoryListPosting: "user/category_posting_list",

  //.. Admin Body Type APIS ..//
  addAdminBodyType: "user/add_used_car_body_type",
  updateAdminBodyType: "user/update_used_car_body_type",
  getAdminBodyType: "user/used_car_body_type",
  deleteAdminBodyType: "user/delete_used_car_body_type",

  //.. Admin About APIS ..//
  addAbout: "user/add_aboutus",
  getAbout: "user/aboutus",
  updateAbout: "user/update_aboutus",
  deleteAbout: "user/delete_aboutus",

  //.. Admin Faq APIS ..//
  addFaq: "user/add_faq",
  getFaq: "user/faq",
  updateFaq: "user/update_faq",
  deleteFaq: "user/delete_faq",

  //.. Admin Contact APIS ..//
  getContacts: "user/contactus",
  deleteContact: "user/delete_contactus",

  //.. Newsletter Contact APIS ..//
  getNewsletter: "user/newsletter",
  deleteNewsletter: "user/delete_newsletter",

  //.. Admin Customer List Dealer List APIS ..//
  admindealers: "user/dealers",
  adminDealersFilter: "customer/dealers_filter",
  updateDealerStatus: "user/update_dealer_status",
  dealerPlans: "user/dealer_active_plan",

  admincustomers: "user/customers",

  //.. Admin Testimonial APIS ..//
  addTestimonial: "user/add_testimonial",
  getAdmintestimonial: "user/testimonial",
  updateTestimonial: "user/update_testimonial",
  deleteTestimonial: "user/delete_testimonial",

  //.. Admin Logo APIS ..//
  getAdminlogo: "user/logo",
  addLogo: "user/add_logo",

  //.. Admin Home Banner Text Heading APIS ..//
  addhome: "user/add_home",
  getAdminhome: "user/home",

  //.. Admin Pricing APIS ..//
  addPrice: "user/add_pricing_plan",
  getPrice: "user/pricing_plan",
  deletePrice: "user/delete_pricing_plan",
  
  updatePrice: "user/update_pricing",
  getPrices: "user/pricing",

  //.. Admin Payments APIS ..//
  getAdminPayments: "user/payment",
  deleteAdminPayment: "user/delete_payment",

  //.. Admin Msg To Seller APIS ..//
  msgToSellerAdmin: "user/msg_to_seller",
  deleteMsgToSellerAdmin: "user/delete_msg_to_seller",
  allUpdateMsgToSellerStatus: "user/update_msg_to_seller_status",
  //.. Admin Settings APIS ..//
  getContactusPage: "user/contactus_page",
  addContactusPage: "user/add_contactus_page",
  getSocialMedia: "user/social_media",
  addSocialMedia: "user/add_social_media",
  //..Bidding APIS...//
  getAdminBidding: "user/posting_bid",
  closeBidding: "user/update_posting_bid_close_status",
  updateBiddingDate: "user/update_posting_bid_end_date",

  //..Admin Enable Bid   APIS ...//
  updateBiddingStatus: "user/update_posting_bid_status",
  biddingUsedCars: "user/bid_posting",

  //..Update Dealer    APIS ..//
  updateDealer: "user/update_dealer",
  dealerAllCars: "user/dealer_posting_list",
  customerListCar: "user/customer_cars_list",
  biddingCarsList: "user/dealer_posting_bidding_list",
  customerBiddingCar: "user/customer_cars_bidding_list",

  getAppUsages: "user/dealers",
  //..........................Admin   APIS End ......................//

  //..........................Dealer   APIS Start ......................//
  //..Dealer Add Cars  APIS  ...//
  addDealerUsedCars: "dealer/add_dealerCars",
  getusedDealerCars: "dealer/dealer_cars",
  usedCarsDealerDetails: "dealer/dealer_cars_details",
  deleteUsedDealerCars: "dealer/delete_dealer_cars",
  updateUsedDealerCars: "dealer/update_dealer_cars",
  updateDealerCarStatus: "dealer/update_car_status",
  //..Dealer Add Cars  APIS ...//
  getDealerBrands: "dealer/used_car_dealer_brand",
  fetchDealerModelByBrand: "dealer/used_car_dealer_model",
  fetchDealerModelByVariant: "dealer/used_car_dealer_varriant",
  fetchDealerCarCategroies: "dealer/used_car_dealer_category",
  fetchDealerBodyTypes: "dealer/used_car_dealer_body_type",
  fetchDealerfuel: "dealer/used_car_dealer_fuel",
  //.. Dealer Login Registration  APIS ..//
  dealerRegistration: "dealer/registration",
  dealerLogin: "dealer/login",
  //.. Dealer Messages  APIS ..//
  dealerAddDealerMsg: "dealer/add_dealer_comunication",
  getDealerMsg: "dealer/dealer_comunication",

  updateDealerDetails: "dealer/update_dealer_profile",
  getdealerDealerDetails: "dealer/dealer_profile",
  updateDealerProfileImage: "dealer/update_dealer_profile_image",

  addDealerPayment: "dealer/add_dealer_payment",
  getDealerPayments: "dealer/dealer_payment",
  //..Dealer Bidding APIS...//
  getDealerBidding: "dealer/dealer_bidding",
  getDealerCarBidding: "dealer/dealer_car_bidding",
  addDealerBidding: "dealer/add_dealer_bidding",
  //.......................Dealer   APIS End .......................//

  //.......................Customer   APIS Start .......................//
  //.. Customer Cars APIS ..//
  customerUsedCars: "customer/used_cars",
  customerUsedCarsDetails: "customer/used_cars_details",

  //.. Customer APIs   ..//
  getCustomerBrands: "customer/customer_car_brand",
  fetchCustomerModelByBrand: "customer/customer_car_model",
  fetchCustomerModelByVariant: "customer/customer_car_varriant",

  fetchCarCategroies: "customer/customer_car_category",
  fetchBodyTypes: "customer/customer_car_body_type",
  fetchfuel: "customer/customer_car_fuel",

  addCustomerUsedCars: "customer/add_customer_cars",
  getCustomerCars: "customer/customer_cars",
  updateCustomerCars: "customer/update_customer_cars",
  updateCustomerCarStatus: "customer/update_customer_car_status",

  //.. Customer Login  APIS ..//
  customerRegistration: "customer/registration",
  customerLogin: "customer/login",

  updateCustomerDetail: "customer/update_customer_profile",
  getCustomerDetails: "customer/customer_profile",
  updateCustomerProfileImage: "customer/update_customer_profile_image",

  //.. Home Banner  APIS ..//
  getCustomerHome: "customer/customer_home",
  //.. Logo    APIS ..//
  getcustomerLogo: "customer/customer_logo",
  //.. Testimonial Login  APIS ..//
  getcustomerTestimonial: "customer/customer_testimonial",
  //.. Contact Add Login  APIS ..//
  addCustomerContactus: "customer/add_customer_contactus",
  //.. Faq Login  APIS ..//
  getCustomerFaq: "customer/customer_faq",
  //.. About  Login  APIS ..//
  getcustomerAboutus: "customer/customer_aboutus",

  getCustomerPrice: "customer/customer_pricing_plan",

  addCustomerMsgToSeller: "customer/add_customer_msg_to_seller",
  //.. Newsletter Add Login  APIS ..//
  addCustomerNewsletter: "customer/add_customer_newsletter",
  addCustomerPayment: "customer/add_customer_payment",
  getCustomerPayments: "customer/customer_payment",
  //..  Settings APIS ..//
  getCustomerContactusPage: "customer/customer_contactus_page",
  getCustomerSocialMedia: "customer/customer_social_media",
  //..Dealer Bidding APIS...//
  addCustomerBidding: "customer/add_customer_bidding",

  //.. Dealer Messages  APIS ..//
  addCustomerMsg: "customer/add_customer_comunication",
  getCustomerMsg: "customer/customer_comunication",
  //.......................Customer   APIS End .......................//

  //spare parts
  addProductCategory: "user/add_product_category",
  updateProductCategory: "user/update_product_category",
  deleteProductCategory: "user/delete_product_category",
  getProductCategories: "user/product_category",

  addProduct: "user/add_product",
  updateProduct: "user/update_product",
  deleteProduct: "user/delete_product",
  getProducts: "user/product",

  getBookings: "user/booking_order",
  updateBookingStatus: "user/update_booking_order_status",

  getInsurances: "user/insurance",
  updateInsuranceStatus: "user/update_insurance_status",
};

export default apiService;
