import React, { Component } from 'react';
import './loadingIcon.scss';
import Loader from "react-loader-spinner";


class LoadingIcon extends Component {
  render() {
    return (
     <Loader
        type="ThreeDots"
        color="#0059a3"
        height={80}
        width={40}
        // timeout={3000} //3 secs
        className='loading-data'

      />    );
  }
}
export default LoadingIcon;
