import React from "react";
import * as Yup from "yup";

const regexExp = /^[6-9]\d{9}$/;
const regexExpAdhar = /^[2-9]{1}[0-9]{11}$/;
const validate = {
  displayError: (touched, errors, variable) => {
    return (
      touched[variable] &&
      errors[variable] && <span className="error">{errors[variable]}</span>
    );
  },
  loginSchema: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
  }),
  registerSchema: Yup.object().shape({
    fullName: Yup.string()
      .min(3, " Name  be at least 3 characters")
      .required(" Name is required"),
    phone: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
    cardNo: Yup.string()
      .matches(regexExpAdhar, "Aadhar Number is not valid")
      .required("Aadhar Number is required"),
    aadharCard: Yup.mixed()
      .nullable()
      .required("Aadhar  is required")
      .test(
        "FILE_SIZE",
        "Uploaded file is too big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      ),
    email: Yup.string().email("Email is invalid").required("Email is required"),
  }),
  mobileNumberSchema: Yup.object().shape({
    phone: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
  }),
  carsSchema: Yup.object().shape({
    selectedBrand: Yup.string().nullable().required("Brand is required"),
    selectedModel: Yup.string().nullable().required("Model is required"),
    selectedVariant: Yup.string().nullable().required("Variant is required"),
    // carPicture: Yup.string().required('Car Image required'),
    car_price: Yup.string()
      .min(2, "Price  is required")
      .required("Price  is required"),
  }),
  testimonialSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name  is required")
      .min(5, "Name  must be at least 5 characters"),
    about: Yup.string()
      .required("About  is required")
      .min(5, "About  must be at least 5 characters"),
    // image: Yup.string().required(' Image required'),
    description: Yup.string()
      .required("Description  is required")
      .min(5, "Description  must be at least 5 characters"),
  }),
  bannerSchema: Yup.object().shape({
    bannerTag: Yup.string()
      .min(3, "Banner Tag must be at least 3 characters")
      .required("Banner Tag is required"),
  }),
  logoSchema: Yup.object().shape({
    logoImage: Yup.string().required("Logo Image required"),
  }),
  brandSchema: Yup.object().shape({
    brandName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  }),
  modelsSchema: Yup.object().shape({
    modelName: Yup.string()
      .min(3, "Model name must be at least 3 characters")
      .required("Model name is required"),
  }),
  fuelSchema: Yup.object().shape({
    fuelName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  }),
  aboutSchema: Yup.object().shape({
    aboutName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  }),
  carCategorySchema: Yup.object().shape({
    categoryName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  }),
  bodyTypeSchema: Yup.object().shape({
    bodyTypeName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
  }),
  submitDetailSchema: Yup.object().shape({
    location: Yup.string()
      .min(3, "Location must be at least 4 characters")
      .required("Location is required"),
    expected_price: Yup.string()
      .min(3, "Please enter a valid number")
      .required("Price is required"),
    phone: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
  }),
  variantsSchema: Yup.object().shape({
    variantName: Yup.string()
      .min(3, "variant name must be at least 3 characters")
      .required("variant name is required"),
  }),
  faqsSchema: Yup.object().shape({
    faqQus: Yup.string()
      .min(3, "Faq Question  must be at least 3 characters")
      .required("Faq Question  is required"),
    faqAns: Yup.string()
      .min(3, "Faq Question  must be at least 3 characters")
      .required("Faq Question  is required"),
  }),
  priceSchema: Yup.object().shape({
    pricingPlanName: Yup.string()
      .min(3, "Plan Name  must be at least 3 characters")
      .required("Plan Name  is required"),
    pricingPlanPrice: Yup.string()
      .test(
        "len",
        "Please enter a Price",
        (val) => val && val.toString().length > 1
      )
      .required("Price is required"),
    pricingPlanDay: Yup.string()
      .test(
        "len",
        "Please enter a Day",
        (val) => val && val.toString().length > 1
      )
      .required("Day is required"),
    pricingPlanItemAllow: Yup.string()
      .test(
        "len",
        "Please enter a Car Allow",
        (val) => val && val.toString().length > 1
      )
      .required("Car Allow is required"),
  }),
  updateCustomerSchema: Yup.object().shape({
    fullName: Yup.string()
      .min(3, " Name  be at least 3 characters")
      .required(" Name is required"),
    pincode: Yup.number()
      .test(
        "len",
        "Please enter a Pincode",
        (val) => val && val.toString().length > 4
      )
      .required("Phone number is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    address: Yup.string()
      .min(3, "Address  be at least 3 characters")
      .required("Address is required"),
  }),
  notificationAddSchema: Yup.object().shape({
    message: Yup.string()
      .min(3, "Message must be at least 3 characters")
      .required("Message is required"),
  }),
  contactSchema: Yup.object().shape({
    fullName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
    phone: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
  }),
  paymentSchema: Yup.object().shape({
    selectedPrice: Yup.string()
      .nullable()
      .required("You must have to select a pricing plan "),
  }),
  newsletterSchema: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  }),
  settingsSchema: Yup.object().shape({
    address: Yup.string()
      .min(3, "Address must be at least 3 characters")
      .required("Address is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
    mapLocation: Yup.string()
      .min(3, "Map Location must be at least 3 characters")
      .required("Map Location is required"),
    emailHeader: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    phoneHeader: Yup.string()
      .matches(regexExp, "Phone number is not valid")
      .required("Phone number is required"),
    copyRight: Yup.string()
      .min(3, "Copy Right must be at least 3 characters")
      .required("Copy Rightis required"),
  }),
  biddingSchema: Yup.object().shape({
    bidding: Yup.number()
      .test(
        "len",
        "Please enter a Bidding Amount",
        (val) => val && val.toString().length > 4
      )
      .required("Bidding Amount is required"),
  }),
  updateDealerStatusSchema: Yup.object().shape({
    regect_reason: Yup.string()
      .min(3, "Reason For Block must be at least 3 characters")
      .required("Reason For Block is required"),
  }),
  enableBidSchema: Yup.object().shape({
    base_price: Yup.number()
      .min(2, "Base Price  is required")
      .required("Base Price  is required"),
    max_price: Yup.number()
      .min(2, "Max Price  is required")
      .required("Max Price  is required")
      .moreThan(Yup.ref("base_price"),"Max price should be greater than Base price"),
    bid_start_date_time: Yup.date()
      .nullable()
      .required("Start Date & Time is required"),
    bid_end_date_time: Yup.date()
      .nullable()
      .required("End Date & Time is required"),
  }),
};
export default validate;
