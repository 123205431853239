import {
  AUTHENTICATE,
  UPDATE_CONFIG,
  UPDATE_LOGIN_FLAG,
  UPDATE_SIGNUP_FLAG,
  CONTACT_DETAILS
} from '../actions/auth';

const initialState = {
  config: {},
  token: null,
  profileData: null,
  mobileNumber: null,
  otp: null,
  dealerRefId: null,
  dealerId: null,
  emailId: null,
  userId: null,
  signInFlag: false,
  singUpFlag: false,
  customerId: null,
  contactDetails: {}
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONFIG:
      return {
        ...state,
        config: action.data
      };

    case AUTHENTICATE:
      return {
        ...state,
        token: action.data.token,
        mobileNumber: action.data.mobileNumber,
        emailId: action.data.emailId,
        customerId: action.data.customerId,
        userId: action.data.userId,
        dealerRefId: action.data.dealerRefId,
        dealerId: action.data.dealerId,
        otp: action.data.otp
      };
    case CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.data
      };
    case UPDATE_LOGIN_FLAG:
      return {
        ...state,
        signInFlag: action.data
      };

    case UPDATE_SIGNUP_FLAG:
      return {
        ...state,
        singUpFlag: action.data
      };
    default:
      return state;
  }
};
export default authReducers;
