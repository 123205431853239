import React, { Component, Suspense, lazy, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import Base from '../../layout/Base'
import './customerRoutes.scss'
import * as Storage from '../../services/localstorage'
import {
  authenticate,
  updateLoginFlag,
  contactDetails
} from '../../../store/actions/auth'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Basepages from '../../layout/Basepages'
import Notifications from 'react-notify-toast'
import { VscClose } from 'react-icons/vsc'
import { useToasts } from 'react-toast-notifications'
import LoadingPage from '../../assets/elements/loadingPage/loadingPage'
import customerService from '../../services/customerService'
import axios from 'axios'
const Home = lazy(() => import('../../containers/customer/home/home'))
const CarsList = lazy(() =>
  import('../../containers/customer/carsList/carsList')
)
const CarDetails = lazy(() =>
  import('../../containers/customer/carDetails/carDetails')
)
const Contact = lazy(() => import('../../containers/customer/contact/contact'))
const Login = lazy(() => import('../../containers/customer/login/login'))
const SellCar = lazy(() => import('../../containers/customer/sellCar/sellCar'))
const About = lazy(() => import('../../containers/customer/about/about'))
const TermsConditions = lazy(() => import('../../containers/customer/termsConditions/termsConditions'))
const PrivacyPolicy = lazy(() => import('../../containers/customer/privacyPolicy/privacyPolicy'))
const Refund = lazy(() => import('../../containers/customer/refund/refund'))
const Faq = lazy(() => import('../../containers/customer/faq/faq'))
const Sitemap = lazy(() => import('../../containers/customer/sitemap/sitemap'))
const UI = lazy(() => import('../../containers/customer/ui/ui'))
const Profile = lazy(() => import('../../containers/customer/profile/profile'))
const Testimonial = lazy(() =>
  import('../../containers/customer/testimonial/testimonial')
)
const ErrorPage = lazy(() => import('../../layout/errorPage'))
const Pricing = lazy(() => import('../../containers/customer/pricing/pricing'))
const listofPages = ['/sell-car', '/error', '/ui']
function withToast (Component) {
  return function WrappedComponent (props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />
  }
}
class CustomerRoutes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ifSessionRestore: false,
      contacts: {}
    }
  }
  componentDidMount () {
    this.checkUserSession()
    this.getContactusPage('reload')
  }

  //Fetch  API
  getContactusPage = reload => {
    if (reload === 'reload') this.setState({ fecthingContactDetails: true })
    var thisView = this
    axios
      .all([customerService.getContactusPage()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]
          if (resData.success) {
            let contacts = resData.data ? resData.data : []
            contacts = contacts.data ? contacts.data : []
            contacts = contacts ? contacts[0] : {}
            thisView.setState({
              contacts
            })
            thisView.props.updatingContactDetails(contacts)
          } else {
            thisView.setState({ apiError: resData.msg })
          }
        }
        thisView.setState({ fecthingContactDetails: false })
      })
      .catch(function (res) {
        console.log(res)
        if (res.message) console.log('An error occurred in Fetching ')
      })
  }

  checkUserSession = () => {
    let session = Storage.get(this.props.config.sessionKey)
    let authData = session ? JSON.parse(session) : {}
    let response = Storage.checkUserSession(authData)
    console.log(authData)
    console.log(response)
    if (response.status) {
      this.props.updatingAuthenticate(response.data)
    } else {
      let notAllowedLoocations = ['profile']
      if (notAllowedLoocations.indexOf(this.props.location.pathname) > -1) {
        this.props.history.push({ pathname: '/home' })
      }
    }
    this.setState({ ifSessionRestore: true })
  }
  closeAuthPopUp = () => {}
  componentWillUnmount () {}

  render () {
    const { location, match, history, mobileNumber } = this.props
    // console.log(location, listofPages.indexOf(location.pathname))
    console.log(mobileNumber)
    return (
      <Fragment>
        {this.state.ifSessionRestore &&
          (listofPages.indexOf(location.pathname) > -1 ? (
            <Basepages {...this.props}>
              <Suspense fallback={<LoadingPage />}>
                <Notifications options={{ zIndex: 1, top: '0' }} />
                <Switch>
                  <Route
                    exact
                    path='/sell-car'
                    render={props => {
                      return <SellCar {...props} {...this.props} />
                    }}
                  />
                  <Route
                    exact
                    path='/ui'
                    render={props => {
                      return <UI {...props} {...this.props} />
                    }}
                  />
                  <Route
                    path='/error'
                    exact={true}
                    render={props => {
                      return <ErrorPage {...props} {...this.props} />
                    }}
                  />
                  <Redirect to='/error' />
                </Switch>
              </Suspense>
            </Basepages>
          ) : (
            <>
              <Base
                location={location}
                match={match}
                history={history}
                {...this.props}
              >
                <Suspense fallback={<LoadingPage />}>
                  <Notifications options={{ zIndex: 1, top: '0' }} />
                  <Switch>
                    <Route
                      exact
                      path='/'
                      render={props => {
                        return <Home {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/home'
                      render={props => {
                        return <Home {...this.props} {...props} />
                      }}
                    />
                    {mobileNumber && (
                      <Route
                        path='/profile'
                        render={props => {
                          return <Profile {...this.props} {...props} />
                        }}
                      />
                    )}
                    <Route
                      exact
                      path='/contact'
                      render={props => {
                        return <Contact {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/about'
                      render={props => {
                        return <About {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/termsCondition'
                      render={props => {
                        return <TermsConditions {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/privacyPolicy'
                      render={props => {
                        return <PrivacyPolicy {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/refund'
                      render={props => {
                        return <Refund {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/pricing'
                      render={props => {
                        return <Pricing {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/sitemap'
                      render={props => {
                        return <Sitemap {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/faq'
                      render={props => {
                        return <Faq {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/testimonial'
                      render={props => {
                        return <Testimonial {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/cardetail/:carId'
                      render={props => {
                        return <CarDetails {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/cars/:brand/:model/:varriant'
                      render={props => {
                        return <CarsList {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/cars/:brand/:model'
                      render={props => {
                        return <CarsList {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/cars/:brand'
                      render={props => {
                        return <CarsList {...this.props} {...props} />
                      }}
                    />
                    <Route
                      exact
                      path='/cars'
                      render={props => {
                        return <CarsList {...this.props} {...props} />
                      }}
                    />
                    <Redirect to='/error' />
                  </Switch>

                  {this.props.signInFlag && (
                    <Modal
                      isOpen={true}
                      className='login-modal'
                      {...this.props}
                    >
                      <ModalHeader>
                        <div className='header-section'>
                          <h3>Login</h3>
                          <VscClose
                            className='close-icon'
                            onClick={this.closeAuthPopUp}
                          />
                        </div>
                      </ModalHeader>
                      <ModalBody>
                        <Login {...this.props} />
                      </ModalBody>
                    </Modal>
                  )}
                </Suspense>
              </Base>
            </>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  config: state.auth.config,
  signInFlag: state.auth.signInFlag,
  mobileNumber: state.auth.mobileNumber
})
const mapDispatchToProps = dispatch => ({
  updatingAuthenticate (data) {
    dispatch(authenticate(data))
  },
  updatingLoginFlag (flag) {
    dispatch(updateLoginFlag(flag))
  },
  updatingContactDetails (data) {
    dispatch(contactDetails(data))
  }
})

// const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(CustomerRoutes))
