import React, { useEffect, useState } from 'react'
import './App.scss'

import axios from 'axios'

import Main from './main'

import storeFactory from './store'
import { Provider } from 'react-redux'
import storeProvider from './store/storeProvider'

function App () {
  const [doneFetchingData, setDoneFetchingData] = useState(false)
  const [reduxKey, setReduxKey] = useState('')

  useEffect(() => {
    axios
      .get('conf/appconfig.json')
      .then(function (res) {
        var appconfig = res.data
        setReduxKey(appconfig.reduxkey)
        setDoneFetchingData(true)
      })
      .catch(function (res) {
        console.log(res)
        console.log('An error occurred config in appjs')
      })
  }, [])

  storeProvider.init(storeFactory)
  const store = storeProvider.getStore()
  const saveState = () =>
    (localStorage[reduxKey] = JSON.stringify(store.getState()))
  store.subscribe(saveState)

  return doneFetchingData ? (
    <Provider store={store}>
      <Main />
    </Provider>
  ) : (
    ''
  )
}

export default App
