import storeProvider from '../../store/storeProvider';
import * as fetchServices from './fetchService';
import apiService from './apiService';
import axios from 'axios';

var customerService = {
  doEncodeURIComponent: function (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, escape);
  },
  //Upload document API
  fileUpload: function (file) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerFileUpload;
    var bodyFormData = new FormData();
    Object.keys(file).map((key) => {
      bodyFormData.append('file', file[key]);
      return true;
    });
    return axios({
      method: 'POST',
      url: API,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      data: bodyFormData
    })
      .then(function (response) {
        console.log(response.data);
        var resData = response.data;
        if (!resData.success) {
          const message = resData.msg;
          const errorRes = { status: false, msg: message, data: message };
          return errorRes;
        }
        const apiResponse = resData;
        return { status: true, data: apiResponse };
      })
      .catch(function (res) {
        console.log(res);
        console.log('An error occurred in File Upload');
      });
  },
  customerRegistration: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerRegistration;

    let payload = {
      ...details
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerLogin: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerLogin;

    let payload = {
      ...details
    };

    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerUsedCars: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerUsedCars;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  customerUsedCarsDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.customerUsedCarsDetails;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  getCustomerBrands: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerBrands;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  fetchCustomerModelByBrand: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.fetchCustomerModelByBrand;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  fetchCustomerModelByVariant: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.fetchCustomerModelByVariant;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  fetchCarCategroies: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.fetchCarCategroies;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Body Type API functions Start .../
  fetchBodyTypes: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.fetchBodyTypes;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Fuel API functions Start .../
  fetchfuel: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.fetchfuel;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Cars  API functions Start .../
  addCustomerUsedCars: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerUsedCars;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getCustomerCars: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerCars;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  updateCustomerCars: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateCustomerCars;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  deleteCustomerCars: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateCustomerCarStatus;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Cars  API functions End .../

  //... Custoemr Udpate Detail API functions Start .../
  updateCustomerDetail: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateCustomerDetail;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Custoemr Udpate Profile API functions Start .../
  updateCustomerProfileImage: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.updateCustomerProfileImage;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Customer Details API functions Start .../
  getCustomerDetails: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerDetails;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //... Home Banner API functions Start .../
  getCustomerHome: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerHome;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //...Logo API functions Start .../
  getcustomerLogo: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getcustomerLogo;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //... Testimonial API functions Start .../
  getcustomerTestimonial: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getcustomerTestimonial;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //... Contact Insert API functions Start .../
  addCustomerContactus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerContactus;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },

  //... Faq API functions Start .../
  getCustomerFaq: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerFaq;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //... About Us API functions Start .../
  getcustomerAboutus: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getcustomerAboutus;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Pricing API functions Start .../
  getPrice: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerPrice;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Message To Seller Insert API functions Start .../
  addCustomerMsgToSeller: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerMsgToSeller;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },

  //... Custoemr Payment Insert API functions Start .../
  addPayment: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerPayment;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Pyament Detail API functions Start .../
  getPayments: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerPayments;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },

  //... Newsletter Insert API functions Start .../
  addNewsletter: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerNewsletter;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, false).then((res) => {
      return res;
    });
  },
  //... Settings  API functions Start .../
  getContactusPage: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerContactusPage;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getSocialMedia: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerSocialMedia;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //... Settings  API functions End .../

  //...  Bidding Insert API functions Start .../
  addCustomerBidding: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerBidding;

    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  //...  Message API functions Start .../
  addCustomerMsg: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.addCustomerMsg;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  },
  getCustomerMsg: function (details) {
    const api = storeProvider.getApi();
    let API = api + apiService.getCustomerMsg;
    let payload = {
      ...details
    };
    return fetchServices.axiosService(API, payload, true).then((res) => {
      return res;
    });
  }
};
export default customerService;
