export const set = (key, value) => localStorage.setItem(key, value)
export const get = key => localStorage.getItem(key)
export const remove = key => localStorage.removeItem(key)
export const clear = () => localStorage.clear()

export const checkUserSession = authData => {
  if (authData.token || authData.otp) {
    let data = {
      token: authData.token,
      mobileNumber: authData.mobileNumber,
      emailId: authData.emailId,
      customerId: authData.customerId,
      userId: authData.userId,
      dealerRefId: authData.dealerRefId,
      dealerId: authData.dealerId,
      otp: authData.otp
    }

    return { status: true, data }
  } else {
    return { status: false, data: '' }
  }
}
