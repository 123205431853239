import qs from "qs";
import storeProvider from "../../store/storeProvider";
import * as Storage from "./localstorage";
import axios from "axios";

const badRespnseCodes = [401, 400];

export const axiosService = (
  API,
  payload,
  authNeeded = true,
  type = "POST"
) => {
  const userInfo = storeProvider.getAdminUserInfo();

  const sessionExpire = (code, msg) => {
    if (code === 99 || msg === "Session expired") {
      Storage.clear();
      window.location.reload();
    }
  };

  let authData = {};
  if (authNeeded) {
    authData = {
      token: userInfo.token,
      emailId: userInfo.emailId,
      user_id: userInfo.userId,
      dealer_id: userInfo.dealerId,
      mobile_no: userInfo.mobileNumber,
      dealer_mobile_otp: userInfo.otp,
      customer_id: userInfo.customerId,
      customer_mobile_otp: userInfo.otp,
      customer_token: userInfo.token,
    };
  }
  let header = {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    // Authorization: authData.token
  };

  // if (type === 'GET') {
  //   header = {
  //     'content-type': 'application/json'
  //     // Authorization: authData.token,
  //   }
  // }

  // console.log(payload, header)
  return axios({
    method: type,
    url: API,

    headers: header,
    params:
      type === "GET"
        ? {
            ...payload,
          }
        : {},
    data: qs.stringify({
      ...authData,
      ...payload,
    }),
  })
    .then(function (response) {
      // console.log(response.data.success)
      var resData = response.data;
      var code = resData.code;
      var msg = resData.msg;
      sessionExpire(code, msg);
      // if (!resData) {
      if (!resData.success) {
        const message = resData.msg;
        const errorRes = {
          status: false,
          data: resData.data,
          code: resData.code,
          msg: message,
          success: resData.success,
        };
        if (authNeeded) {
          const sessionResp = checkSession(errorRes);
          return sessionResp;
        } else {
          return errorRes;
        }
      }
      const apiResponse = resData;
      return { status: true, data: apiResponse, success: true };
    })
    .catch(function (res) {
      if (
        res &&
        res.response &&
        res.response.status &&
        badRespnseCodes.indexOf(res.response.status) > -1
      ) {
        checkResponseCode(res);
      }
      let data = res?.response || {};
      var code = data.code;
      var msg = data.msg;
      sessionExpire(code, msg);
      console.log(res);
      console.log("An error occurred in " + API + "  service");
      return {
        status: false,
        ...res.response.data,
      };
    });
};

const checkResponseCode = (data) => {
  Storage.clear();
  window.location.reload();
};

const checkSession = function (data) {
  let message = data.msg;
  //let message = data.msg ? data.msg : (data.message ? data.message : '');

  if (typeof message === "string" || message instanceof String) {
    message = message ? message.toLowerCase() : "";
    message = message.trim();
    if (
      message === "id or token wrong." ||
      message === "email id or token wrong."
    ) {
      // store.dispatch(authActions.sessionExpired(true));
      const sessionKey = storeProvider.getSessionKey();
      Storage.remove(sessionKey);
      window.location.reload();

      return { ...data, sessionExpired: true };
    }
  }

  return { ...data, sessionExpired: false };
};

export const notify = () => {
  let notifyStyle = { background: "#ffe208", text: "#000" };

  return {
    notifyStyle,
  };
};
