import React from 'react'
import { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
// import Base from '../../layout/Base';
// import Base from '../../layout/AdminBase'
// import Base from '../../layout/AdminBase'
import Basepages from '../../layout/Basepages'
import DealerBase from '../../layout/DealerBase'
import './dealerRoutes.scss'
import { authenticate } from '../../../store/actions/auth'
import * as Storage from '../../services/localstorage'
import Notifications from 'react-notify-toast'
import { useToasts } from 'react-toast-notifications'
import LoadingPage from '../../assets/elements/loadingPage/loadingPage'
const Login = lazy(() => import('../../containers/dealer/login/login'))
const Register = lazy(() => import('../../containers/dealer/register/register'))
const Profile = lazy(() => import('../../containers/dealer/profile/profile'))
const ErrorPage = lazy(() => import('../../layout/errorPage'))

const listofPages = ['/login', '/', '/error','/register']
function withToast (Component) {
  return function WrappedComponent (props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />
  }
}
class DealerRoutes extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    // console.log('tools main')
    this.checkUserSession()
  }

  checkUserSession = () => {
    let session = Storage.get(this.props.config.sessionKey)
    let authData = session ? JSON.parse(session) : {}
    let response = Storage.checkUserSession(authData)
    if (response.status) {
      this.props.updatingAuthenticate(response.data)
    } else {
      this.props.history.push({ pathname: '/login' })
    }
  }

  componentWillUnmount () {}

  render () {
    const { location } = this.props
    // console.log(location, listofPages.indexOf(location.pathname))
    return listofPages.indexOf(location.pathname) > -1 ? (
      <Basepages {...this.props}>
        <Suspense fallback={<LoadingPage />}>
          <Notifications options={{ zIndex: 1, top: '0' }} />
          <Switch>
            <Route
              exact
              path='/login'
              render={props => {
                return <Login {...props} {...this.props} />
              }}
            />
            <Route
              exact
              path='/register'
              render={props => {
                return <Register {...props} {...this.props} />
              }}
            />
            <Route
              exact
              path='/'
              render={props => {
                return <Login {...props} {...this.props} />
              }}
            />
            <Route
              path='/error'
              exact={true}
              render={props => {
                return <ErrorPage {...props} {...this.props} />
              }}
            />
            <Redirect to='/error' />
          </Switch>
        </Suspense>
      </Basepages>
    ) : (
      // <Base location={location} {...this.props}>
      <DealerBase location={location} {...this.props}>
        <Suspense fallback={<LoadingPage />}>
          <Notifications options={{ zIndex: 1, top: '0' }} />
          <Switch>
            <Route
              path='/profile'
              render={props => {
                return <Profile {...this.props} {...props} />
              }}
            />
            <Redirect to='/error' />
          </Switch>
        </Suspense>
        {/* </Base> */}
      </DealerBase>
    )
  }
}

const mapStateToProps = state => ({
  config: state.auth.config,
  emailId: state.auth.emailId
})

const mapDispatchToProps = dispatch => ({
  updatingAuthenticate (data) {
    dispatch(authenticate(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToast(DealerRoutes))
