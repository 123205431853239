var store = undefined

const storeProvider = {
  init (configureStore) {
    store = configureStore({})
  },
  getStore () {
    return store
  },
  getCurrentState () {
    return store.getState()
  },
  getApi () {
    var currentState = this.getCurrentState()
    return currentState.auth.config.api
  },
  getCustomerType () {
    var currentState = this.getCurrentState()
    return currentState.auth.config.customerType
  },
  getAdminUserInfo () {
    var currentState = this.getCurrentState()
    var data = {
      emailId: currentState.auth.emailId,
      mobileNo: currentState.auth.mobileNo,
      token: currentState.auth.token,
      customerId: currentState.auth.customerId,
      userId: currentState.auth.userId,
      dealerRefId: currentState.auth.dealerRefId,
      dealerId: currentState.auth.dealerId,
      mobileNumber: currentState.auth.mobileNumber,
      otp: currentState.auth.otp
    }
    return data
  },
  getSessionKey () {
    var currentState = this.getCurrentState()
    return currentState.auth.config.sessionKey
  }
}
export default storeProvider
