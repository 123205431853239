import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
// import * as Storage from '../services/localstorage';
// import Logo from '../images/home/large.png'
import "./adminSidebar.scss";
import logo from "../../../assets/images/logo.jpeg";
import { Collapse } from "reactstrap";
import * as Storage from "../../../services/localstorage";
class AdminSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuActive: false,
      openSiteManagement: false,
      openCarSection: false,
      toggleMenu: {},
    };
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuActive: !this.state.mobileMenuActive });
  };

  logout = (event) => {
    event.preventDefault();
    Storage.remove(this.props.config.sessionKey);
    window.location.reload();
  };
  toggleCarSection = (field) => {
    this.setState({ openCarSection: !this.state.openCarSection });
  };
  toggleSiteManagement = (field) => {
    this.setState({ openSiteManagement: !this.state.openSiteManagement });
  };
  onToggleMenu = (menu) => {
    const { toggleMenu } = this.state;
    this.setState({ toggleMenu: { [menu]: toggleMenu[menu] ? false : true } });
  };

  dropDownSymbol = (menu) => {
    const { toggleMenu } = this.state;
    return (
      <span className="dropdown-symbol ml-3">
        {toggleMenu[menu] ? (
          <i className="fa fa-caret-up"></i>
        ) : (
          <i className="fa fa-caret-down"></i>
        )}
      </span>
    );
  };

  render() {
    const { toggleMenu } = this.state;
    return (
      <div className="side-nav-main admin">
        <nav className="side-nav">
          <div className="sb-logo">
            <div className="logo  mx-auto">
              <img src={logo} alt="Logo" className="admin-logo" />
              {/* <h3 className='logo-admin-text'>Admin</h3> */}
            </div>
          </div>

          <div className="side-nav__devider my-6"></div>
          <ul>
            <li>
              <NavLink
                to={"/home"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="fa fa-tachometer-alt fa-lg mr-3"></i> Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/carsList"}
                className="side-menu"
                activeClassName="side-menu--active"
                onClick={() => this.onToggleMenu("cars")}
              >
                <span>
                  <i className="far fa-dot-circle fa-lg mr-3"></i>
                  <span className="sidebar-menu-heading">Posting Section</span>
                </span>
                {this.dropDownSymbol("cars")}
              </NavLink>
            </li>
            <div className="site-management-div">
              <Collapse isOpen={toggleMenu.cars}>
                <li>
                  <li>
                    <NavLink
                      to={"/carsList"}
                      className="side-menu"
                      activeClassName="side-menu--active"
                    >
                      <i className="fa fa-car fa-lg ml-3  mr-3"></i>
                      Active Postings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/listontop"}
                      className="side-menu"
                      activeClassName="side-menu--active"
                    >
                      <i className="fa fa-car fa-lg ml-3  mr-3"></i>
                      List On Top
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/pendingcars"}
                      className="side-menu"
                      activeClassName="side-menu--active"
                    >
                      <i className="fa fa-car fa-lg ml-3  mr-3"></i>Pending
                      Postings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/biddingcars"}
                      className="side-menu"
                      activeClassName="side-menu--active"
                    >
                      <i className="fa fa-car fa-lg ml-3  mr-3"></i> Biddings
                    </NavLink>
                  </li>
                </li>
              </Collapse>
            </div>

            <li>
              <NavLink
                to={"/carcategory"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>
                Categories
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/logo"}
                className="side-menu"
                activeClassName="side-menu--active"
                onClick={() => this.onToggleMenu("site")}
              >
                <span>
                  <i className="far fa-dot-circle fa-lg mr-3"></i>
                  <span className="sidebar-menu-heading">Site Management</span>
                </span>
                {this.dropDownSymbol("site")}
              </NavLink>
            </li>
            <div className="site-management-div">
              <Collapse isOpen={toggleMenu.site}>
                <li>
                  <NavLink
                    to={"/logo"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Logo
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/banner"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Banner
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/settings"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/testimonial"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Testimonial
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/newsletter"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Newsletter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/contacts"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/about"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/pricing"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/faqs"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Faqs
                  </NavLink>
                </li>
              </Collapse>
            </div>
            <li>
              <NavLink
                to={"/product-category"}
                className="side-menu"
                activeClassName="side-menu--active"
                onClick={() => this.onToggleMenu("spare")}
              >
                <span>
                  <i className="far fa-dot-circle fa-lg mr-3"></i>
                  <span className="sidebar-menu-heading">Spare Parts</span>
                </span>
                {this.dropDownSymbol("spare")}
              </NavLink>
            </li>
            <div className="site-management-div">
              <Collapse isOpen={toggleMenu.spare}>
                <li>
                  <NavLink
                    to={"/product-category"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Product Category
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/products"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/bookings"}
                    className="side-menu"
                    activeClassName="side-menu--active"
                  >
                    <i className="far fa-dot-circle fa-lg ml-3 mr-3" />
                    Bookings
                  </NavLink>
                </li>
              </Collapse>
            </div>

            <li>
              <NavLink
                to={"/app-usage"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>
                App Usage
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/insurance"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>
                Insurance
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/payments"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>
                Payments
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/dealers"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>
                Dealers
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to={"/customers"}
                className="side-menu"
                activeClassName="side-menu--active"
              >
                <i className="far fa-dot-circle fa-lg mr-3"></i>Customers
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to={"/login"}
                className="side-menu"
                activeClassName=""
                onClick={this.logout}
              >
                <i className="fa fa-sign-out-alt fa-lg mr-3"></i>
                Logout
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.auth.config,
  adminNotifications: state.auth.adminNotifications,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
