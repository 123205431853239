import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import AdminRoute from './components/routes/adminRoute/adminRoute'
import CusomterRoute from './components/routes/customerRoute/customerRoute'
import DealerRoute from './components/routes/dealerRoute/dealerRoute'

import { updateConfig } from './store/actions/auth'
import { ToastProvider } from 'react-toast-notifications'

class Main extends Component {
  constructor (props) {
    super(props)
    this.state = { appconfig: null, readConfigureDone: false }
  }

  componentDidMount () {
    // console.log('main')
    this.getConfig()
  }

  getConfig = () => {
    var thisView = this
    axios
      .get('conf/appconfig.json')
      .then(function (res) {
        var appconfig = res.data
        thisView.props.updatingConfig(appconfig)

        thisView.setState({ appconfig, readConfigureDone: true })
      })
      .catch(function (res) {
        console.log(res)
        console.log('An error occurred config')
      })
  }

  // getRoute = route => {
  //   if (route === 'customer') return <CusomterRoute />
  //   else if (route === 'admin') return <AdminRoute />
  //   else return <CusomterRoute />
  // }
  getRoute = route => {
    if (route === 'admin') return <AdminRoute />
    else if (route === 'customer') return <CusomterRoute />
    else if (route === 'dealer') return <DealerRoute />
    else return <DealerRoute />
  }

  render () {
    return this.state.appconfig ? (
      <div
        className={`app ${
          this.state.appconfig.source === 'admin' ? 'admin-app' : ''
        }`}
      >
        <ToastProvider>
          {this.getRoute(this.state.appconfig.source)}
        </ToastProvider>
      </div>
    ) : (
      <span></span>
    ) //Dummy return instead of empty in render
  }
}

const mapStateToProps = state => ({
  config: state.auth.config
})

const mapDispatchToProps = dispatch => ({
  updatingConfig (data) {
    dispatch(updateConfig(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
