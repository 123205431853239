import React, { Component } from "react";
// import Footer from './Footer';
// import Header from './Header';
import AdminSidebar from "./sidebar/adminSidebar/adminSidebar";
import { connect } from "react-redux";
import Header from "./header/header";

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleactive: false,
    };
  }

  getRoute = (route) => {
    return <AdminSidebar className="sb_sidebar" />;
  };

  render() {
    const { config } = this.props;
    return (
      <div
        className={this.state.toggleactive ? "wrapper  slide-menu" : "wrapper"}
      >
        <div className="container-fluid admin-fluid">
          <div className="row">
            {this.getRoute(config.source)}
            <div className="content sb-mc">
              <Header {...this.props} />
              <div className="grid grid-cols-12 gap-6 admin-main-content">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  config: state.auth.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Base);
