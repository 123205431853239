import React, { Component } from 'react'
import './header.scss'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as Storage from '../.././services/localstorage'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  logout = event => {
    event.preventDefault()
    Storage.remove(this.props.config.sessionKey)
    window.location.reload()
  }

  render () {
    const { config } = this.props
    return (
      <div className='header-container-admin'>
        <div
          className={
            config.source === 'customer'
              ? 'customer-header-content'
              : 'header-content'
          }
        >
          <p className='login-signup'>
            <NavLink to={'/login'} onClick={this.logout}>
              <i className='fa fa-sign-out-alt fa-lg mr-1'></i>
              Logout
            </NavLink>
          </p>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  config: state.auth.config
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
