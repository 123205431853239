import React, { Component } from 'react'
// import Footer from './Footer';
// import Header from './Header'
// import Footer from './Footer'
// import Sidebar from './Sidebar';

class DealerBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggleactive: false
    }
  }

  render () {
    return (
      <div
        className={this.state.toggleactive ? 'wrapper  slide-menu' : 'wrapper'}
      >
        {/* <Header
          match={this.props.match}
          location={this.props.location}
          history={this.props.history}
        /> */}
        <div className='container-fluid' style={{ padding: '0' }}>
          <div className='row' style={{ display: 'contents' }}>
            <div className='content'>
              <div className='grid grid-cols-12 gap-6 main-base-container'>
                {this.props.children}
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default DealerBase
